.card {
    width: 175px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white 0% 0% no-repeat padding-box;
    border: $light-gray solid 1px;
    border-radius: 10px;
    color: $dark-gray;
    transition: all 0.23s ease;

    @media (max-width: calc(640px + 75px)) {
        width: 100px;
        height: 70px;
    }

    &:hover {
        background-color: $light-gray;
        cursor: pointer;
    }

    &:focus {
        background-color: $black;
    }

    &--selected {
        background-color: $black;
        color: white;

        &:hover {
            background-color: $black;
            color: white;
        }
    }

    &__currency {
        font-size: $currency;
    }

    &__value {
        font-size: $value;
    }
}
