@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @-webkit-keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }
  
  @-moz-keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }