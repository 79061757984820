.radio__item {
    display: table;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-weight: $regular;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
            background-color: $light-gray;

            &:after {
                display: block;
            }
        }
    }

    &:hover input ~ .checkmark {
        background-color: darken($light-gray, 5%);
    }

    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: $light-gray;
        border: 1px solid $dark-gray;
        border-radius: 50%;

        &:after {
            top: 2px;
            left: 2px;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: $dark-gray;
            content: "";
            position: absolute;
            display: none;
        }
    }
  }