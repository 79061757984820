.submit {
    width: 100%;
    height: 65px;
    margin: 15px 0;
    font-size: 18px;
    font-weight: $semi-bold;
    line-height: 24px;
    color: white;
    background: $default-primary 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.submit:hover{
    opacity:0.7;
}

