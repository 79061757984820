.form__main {
    .main {

        &__amount {
            display: flex;
            flex-direction: column;
            padding: 25px 40px 0;
            box-sizing: border-box;

            @media (max-width: calc(640px + 75px)) {
                padding: 25px 20px 0;
            }

            .break {
                flex-basis: 100%;
            }

            .newLine {
                margin-top: 10px;
            }

            .amount {
                &__cards {
                    margin: 20px 0 10px;
                    justify-content: space-between;
                    display: flex;
                    flex-wrap: wrap;
                }

                &__custom {
                    width: 100%;
                    height: 65px;
                    margin: 10px 0;
                    background: $light-gray 0% 0% no-repeat padding-box;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;

                    .custom {
                        &__currency {
                            width: 90px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $dark-gray;
                            font-size: $currency;
                            background: $gray 0% 0% no-repeat padding-box;
                            border-radius: 10px 0px 0px 10px;
                        }

                        &__value {
                            width: 100%;
                            background: $light-gray 0% 0% no-repeat padding-box;
                            border: 1px solid $gray;
                            border-radius: 0 10px 10px 0;
                            padding: 30px;
                            appearance: textfield;

                            @include remove-arrow-number-input;

                            @include input-placeholder {
                                text-align: left;
                                font-size: 18px;
                                font-weight: $regular;
                                letter-spacing: 0;
                                color: $dark-gray;
                            }
                        }
                    }
                }
            }
        }

        &__data {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            .data {
                &__form {
                    padding: 25px 40px 0;
                    display: flex;
                    flex-direction: column;

                    @media (max-width: calc(640px + 75px)) {
                        padding: 25px 20px 0;
                    }

                    h2 {
                        margin-bottom: 10px;
                    }

                    hr {
                        height: 1px;
                        width: 100%;
                        margin: 30px 0;
                        border: none;
                        background-color: $gray;
                    }

                    .form__group {
                        display: flex;
                        flex-direction: column;
                        margin-top: 15px;

                        &__hidden {
                            display: none;
                        }

                        input[type=checkbox] {
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        input[type=radio] {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                        }

                        input[type=text] {
                            height: 50px;
                            margin-top: 10px;
                            padding: 0 15px;
                            background: $light-gray 0% 0% no-repeat padding-box;
                            border: 1px solid $gray;
                            border-radius: 10px;

                            @include input-placeholder {
                                text-align: left;
                                font-size: 18px;
                                font-weight: $regular;
                                letter-spacing: 0;
                                color: $dark-gray;
                            }
                        }

                        select {
                            background: $light-gray 0% 0% no-repeat padding-box;
                            border: 1px solid $gray;
                            border-radius: 10px;
                            opacity: 1;
                            height: 50px;
                            padding: 0 15px;
                        }

                        textarea {
                            min-height: 150px;
                            max-height: 300px;
                            resize: vertical;
                            margin-top: 10px;
                            padding: 15px;
                            background: $light-gray 0% 0% no-repeat padding-box;
                            border: 1px solid $gray;
                            border-radius: 10px;
                            text-align: left;
                            font-size: 18px;
                            font-weight: $regular;
                            line-height: 24px;
                            letter-spacing: 0;
                            color: $dark-gray;

                            @include input-placeholder {
                                text-align: left;
                                font-size: 18px;
                                font-weight: $regular;
                                line-height: 24px;
                                letter-spacing: 0;
                                color: $dark-gray;
                            }
                        }

                        .group__radio {
                            display: flex;
                            flex-direction: column;

                            div {
                                margin-top: 10px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            }
                        }

                        .group__checkbox {
                            margin-bottom: 10px;

                            label {
                                font-weight: $regular;

                                u {
                                    cursor: pointer;
                                }
                            }

                            .company__form {
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height 500ms ease-in-out;

                                label {
                                    font-weight: $semi-bold;
                                }
                            }
                        }

                        &.form__target {
                            margin-top: 30px;

                            .target {
                                &__title {
                                    margin-bottom: 15px;
                                }

                                &__someone {
                                    padding-left: 35px;
                                    max-height: 0;
                                    overflow: hidden;
                                    transition: max-height 0.5s ease-in-out;

                                    @media (max-width: calc(640px + 75px)) {
                                        padding-left: 0;
                                    }
                                }
                            }

                            #receiver__form {


                                @media (max-width: calc(640px + 75px)) {
                                    padding-left: 0;
                                }
                            }
                        }

                        &.form__conditions {
                            margin-top: 0;
                            margin-bottom: 30px;



                            .company__form {
                                padding-left: 25px;

                                @media (max-width: calc(640px + 75px)) {
                                    padding-left: 0;
                                }
                            }

                            .group__checkbox {
                                label {
                                    @media (max-width: calc(640px + 75px)) {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__current-gift {
            width: 100%;
            padding: 10px 40px;
            background: $gray 0% 0% no-repeat padding-box;
            text-align: left;
            font-size: 15px;
            font-weight: $semi-bold;
            line-height: 20px;
            color: $black;
            box-sizing: border-box;

            @media (max-width: calc(640px + 75px)) {
                padding: 10px 20px;
            }

            .gift {
                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    cursor: pointer;

                    div {
                        width: 100%;
                    }

                    .header {
                        &__amount {
                            text-align: left;

                            @media (max-width: calc(640px + 75px)) {
                                display: none;
                            }
                        }

                        &__total {
                            text-align: center;
                        }

                        &__action {
                            text-align: right;

                            .action__arrow {
                                @include transition();
                                margin-right: 20px;

                                &--open {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }

                &__customisation {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.3s ease-out;

                    hr {
                        height: 2px;
                        width: 100%;
                        margin: 15px 0 30px;
                        border: none;
                        background-color: darken($gray, 5%);
                    }

                    .customisation {
                        &__cards {
                            .cards__item {
                                margin: 20px 0;
                                padding: 25px 20px;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                background: $light-gray 0% 0% no-repeat padding-box;
                                border-radius: 10px;

                                @media (max-width: calc(640px + 75px)) {
                                    display: block;
                                }

                                .item {
                                    &__amount, &__title, &__actions {
                                        width: 100%;
                                    }

                                    &__amount {
                                        height: 35px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: stretch;
                                        text-align: left;

                                        @media (max-width: calc(640px + 75px)) {
                                            height: 45px;
                                            margin-bottom: 20px;
                                        }

                                        .amount {
                                            &__change {
                                                width: 30px;
                                                background-color: $light-gray;
                                                border: 1px solid $gray;
                                                cursor: pointer;

                                                @media (max-width: calc(640px + 75px)) {
                                                    width: 20%;
                                                }
                                            }

                                            &__value {
                                                min-width: 35px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                background-color: $white;
                                                border-top: 1px solid $gray;
                                                border-bottom: 1px solid $gray;
                                                -webkit-user-select: none;
                                                -moz-user-select: none;
                                                -ms-user-select: none;
                                                user-select: none;


                                                @media (max-width: calc(640px + 75px)) {
                                                    width: 60%;
                                                }
                                            }
                                        }

                                        .decrement {
                                            border-radius: 10px 0 0 10px;
                                        }

                                        .increment {
                                            border-radius: 0 10px 10px 0;
                                        }
                                    }

                                    &__title {
                                        align-self: center;
                                        text-align: center;
                                        font-size: 18px;
                                        font-weight: $semi-bold;
                                        line-height: 24px;

                                        @media (max-width: calc(640px + 75px)) {
                                            display: block;
                                            text-align: left;
                                            float: left;
                                            width: 80%;
                                            margin-top: -10px;
                                        }
                                    }

                                    &__actions {
                                        align-self: center;
                                        text-align: right;

                                        @media (max-width: calc(640px + 75px)) {
                                            float: right;
                                            width: 20%;
                                            margin-top: -10px;
                                        }

                                        svg, i {
                                            color: $dark-gray;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                        &__add {
                            margin: 30px 0 15px;
                            text-align: center;
                            font-size: 15px;
                            font-weight: $semi-bold;
                            line-height: 24px;

                            span {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        &__summary {
            padding: 65px 100px;
            display: flex;
            justify-content: center;

            .summary__container {
                display: flex;
                flex-direction: column;
                align-items: center;

                .container__title {
                    color: #07BF44;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: $semi-bold;

                    @media (max-width: calc(640px + 75px)) {
                        font-size: 16px;
                    }
                }

                .container__title_error {
                    color: #CC1C1C;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: $semi-bold;

                    @media (max-width: calc(640px + 75px)) {
                        font-size: 16px;
                    }
                }

                .container__message {
                    margin-top: 35px;
                    color: $black;
                    font-size: 20px;
                    line-height: 27px;
                    text-align: center;

                    @media (max-width: calc(640px + 75px)) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
