.backdrop {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease-in-out;
    
    &.is-blacked-out {
        opacity: 1;
        visibility: visible;
    }
}

.modal {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    width: 640px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 0 25px 40px;
    z-index: 1001;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 30px;
    background-color: $white;
    transition: opacity 0.1s ease-in-out;

    @media (max-width: calc(640px + 75px)) {
        width: 100%;
    }

    button {
        height: 60px;
        width: 100%;
        border: 1px solid #1C1C1C;
        border-radius: 10px;
        background-color: $black;
        color: $white;
        cursor: pointer;
    }

    &.is-active {
        visibility: visible;
        opacity: 1;
    }

    &__container {
        overflow-y: auto;
        max-height: 600px;
        margin-right: 10px;
        padding-right: 25px;


        @media (max-width: calc(640px + 75px)) {
            max-height: 300px;

            h1{
                font-size:24px;
            }
        }
    }

    &__content {
        margin: 45px 0;
        font-size: 16px;
        line-height: 30px;

        p {
            margin-bottom: 10px;
        }
    }
}