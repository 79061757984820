@import '_animations.scss';
@import '_mixins.scss';
@import '_variables.scss';

body, html {
    height: 100vh;
    display: block;
    width: 100vw;
    margin: 0;
    padding: 0;
  
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: $black;
    overflow-wrap: break-word;
    overflow-x: hidden;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;

    &::before {
        width: 100%;
        height: 100%;
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        opacity: 0.5;
        z-index: -1;
    }
}
  
h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
}

h1 {
    text-align: left;
    font-size: 30px;
    font-weight: $bold;
    line-height: 41px;
}

h2 {
    text-align: left;
    font-size: 22px;
    font-weight: $semi-bold;
    line-height: 30px;
}

h3 {
    text-align: left;
    font-size: 18px;
    font-weight: $regular;
    line-height: 24px;
    color: $dark-gray;
}

label {
    text-align: left;
    font-size: 18px;
    font-weight: $semi-bold;
    line-height: 24px;
    color: $black;
}

textarea {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.small-text {
    text-align: left;
    font-size: 10px;
    font-weight: $semi-bold;
    line-height: 12px;
    letter-spacing: 0;
    color: $black;
}