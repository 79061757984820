.progress {
    &__bar {
        height: 10px;
        position: relative;
        background-color: $gray;
        border-radius: 10px;
        overflow: hidden;

        span {
            display: block;
            height: 100%;
            transition: width 0.7s ease-in-out;

            .bar {
                border-radius: 10px;
                animation: progressBar 1.5s ease-in-out;
                animation-fill-mode: both;
                -webkit-animation: progressBar 1.5s ease-in-out;
                -webkit-animation-fill-mode:both; 
                -moz-animation: progressBar 1.5s ease-in-out;
                -moz-animation-fill-mode:both; 
            }
        }
    }

    &__steps {
        display: flex;
        margin: 10px 0;
        justify-content: space-between;

        span {
            font-size: 12px;
            font-weight: $semi-bold;
            line-height: 17px;
            color: $black;

            b {
                font-weight: $extra-bold;
            }
        }
    }
}