.dropdown {
    position: reltaive;
    user-select: none;
    width: 100%;
    margin-bottom: 20px;

    &__select {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid $gray;
        border-radius: 10px;

        &.open {
            > .select__options {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }
        }

        .select {
            &__trigger {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 22px;
                font-size: 17px;
                font-weight: $light;
                color: $black;
                height: 50px;
                line-height: 60px;
                background-color: $light-gray;
                cursor: pointer;
                border: 1px solid $gray;
                border-radius: 10px;
            }

            &__options {
                position: absolute;
                display: block;
                top: 100%;
                left: 0;
                right: 0;
                max-height: 275px;
                overflow-y: auto;
                border: 1px solid $gray;
                border-radius: 10px;
                border-top: 0;
                background: $white;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                z-index: 2;
                
                @include transition();

                .option {
                    position: relative;
                    display: block;
                    background-color: $light-gray;
                    padding: 0 22px;
                    font-size: 17px;
                    font-weight: $light;
                    color: $black;
                    line-height: 50px;
                    cursor: pointer;
                    
                    @include transition();

                    &:hover {
                        cursor: pointer;
                        background-color: $gray;
                    }
                }

                .selected, .selected:hover {
                    color: $white;
                    background-color: $dark-gray;
                }
            }
        }
    }
}

.country__select {
    padding: 0;
    margin-bottom: 10px;

    .select-search-box {
        width: 100%;
        position: relative;

        input[type=search] {
            line-height: 1;
        }

        &__search {
            display: block;
            width: 100%;
            height: 50px;
            border: none;
            background: none;
            outline: none;
            font-size: 16px;
            padding: 0 20px;
            color: $dark-gray;
            -webkit-appearance: none;
            box-sizing: border-box;
            position: relative;
            z-index: 2;
            cursor: pointer;
            line-height: 50px;

            &--selected {
                cursor: text;
            }
        }

        &__select {
            display: none;
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            background: $light-gray 0% 0% no-repeat padding-box;
            border-radius: 10px;
            overflow: auto;
            z-index: 100;
            min-height: 49px;
            box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);

            &::-webkit-scrollbar {
                width: 10px;
                background-color: $white;
            }
        
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
                border-radius: 5px;
                background-color: $white;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $dark-gray;
                border-radius: 5px;
            }

            &--display {
                max-height: 275px;
                display: block;
            }
        }

        &__options {
            margin: 0;
            padding: 0;
        }

        &__option {
            font-size: 16px;
            font-weight: 400;
            color: $black;
            padding: 15px 20px;
            border-top: 1px solid $gray;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: none;
            @include transition();
            
            &:hover {
                background: darken($light-gray, 5%);
                border-top-color: darken($light-gray, 5%);
            }

            &:first-child {
                border-top: none;
            }

            &--selected {
                background: $dark-gray;
                color: $white;
                border-top-color: $dark-gray;
                @include transition();

                &:hover {
                    background: darken($dark-gray, 5%);
                    border-top-color: darken($dark-gray, 5%);
                }
            }
        }
    }
}