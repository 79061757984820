@mixin transition($duration: .3s, $easing: ease-out) {
    transition: all $duration $easing;
}

@mixin input-placeholder {
    @content;
    &::-webkit-input-placeholder { @content; }
    &:-moz-placeholder           { @content; }
    &::-moz-placeholder          { @content; }
    &:-ms-input-placeholder      { @content; }  
}

@mixin remove-arrow-number-input {
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }
    &::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    &[type=number] {
         -moz-appearance:textfield; 
    }
}