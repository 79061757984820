@import '_footer.scss';
@import '_header.scss';
@import '_main.scss';

.container {
    display: flex;
    justify-content: center;

    &__form {
        margin: 75px;
        width: 640px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $white 0% 0% no-repeat padding-box;
        border-radius: 30px;
        opacity: 1;

        @media (max-width: calc(640px + 75px)) {
            width: auto;
            max-width: 100%;
            margin: 0;
            min-height: 100vh;
            border-radius: 0;
        }
    }
}