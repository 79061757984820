/* COLORS */

$white: #FFFFFF;
$light-gray: #F2F2F2;
$gray: #E6E6E6;
$dark-gray: #9F9F9F;
$black: #1C1C1C;

$default-primary: #ffce00;

/* FONT */
// FONT WEIGHT

$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$ultra-bold: 900;

// FONT SIZE

$currency: 30px;
$value: 35px;