.custom-scrollbar {
    &::-webkit-scrollbar {
        width: 7px;
        border-radius: 4px;
        background-color: $white;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
        border-radius: 4px;
        background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark-gray;
        border-radius: 4px;
    }
}

.gray-scrollbar {
    &::-webkit-scrollbar-thumb {
        background-color: $gray !important;
    }
}