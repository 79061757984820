.form__footer {
    width: 100%;
    padding: 0 40px 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    box-sizing: border-box;

    .footer__text {
        font-size: 12px;
        font-weight: $thin;
        line-height: 17px;

        a {
            color: black;
        }
    }
}