.form__header {
    width: 100%;
    padding: 25px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background: $light-gray 0% 0% no-repeat padding-box;
    border-radius: 30px 30px 0px 0px;

    @media (max-width: calc(640px + 75px)) {
        padding: 25px 20px 0;
    }


    .header {
        &__intro {
            display: flex;
            flex-direction: column;

            .intro {
                &__title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .title__language {
                        padding-left: 10px;
                        display: flex;
                        flex-direction: row;
                        align-self: flex-end;
                        border-left: 1px solid $dark-gray;

                        p {
                            margin-right: 5px;
                        }

                        .selectlanguage {
                            border: none;
                            background-color: transparent;
                            font-weight: bold;
                            font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
                        }
                    }
                }

                &__subtitle {
                    h3 > b {
                        font-weight: $semi-bold;
                    }

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: 5px;
                }

                &__address {
                    padding-bottom: 15px;
                }
            }
        }

        &__progress {
            margin-top: 5px;
        }

        &__back {
            margin-left: -26px;
            padding-right: 15px;
            color: black !important;



            @media (max-width: calc(640px + 75px)) {
                display: none;
            }
        }

        &__url {
            text-decoration: none !important;
        }
    }
}
