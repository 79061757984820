.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #ffce00; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    @media (max-width: calc(640px + 75px)) {
        width: 35px;
        height: 35px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
