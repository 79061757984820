.container__background-image {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;

    &::before {
        width: 100%;
        height: 100%;
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        opacity: 0.4;
    }

    img {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}